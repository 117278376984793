<div class="video-component-container" [ngClass]="{ 'rise-admin': userState.isRiseAdmin() }">
  <div class="attribute-editor-component video-component-settings"
    [shown]="showSettingsUI()"
  >
    <div class="attribute-editor-row">
      <div class="video-component-volume">
        <label>Volume</label>
        <div class="row">
          <div class="col-xs-12">
            <input min="0" max="100" type="range"
              [(ngModel)]="values.volume"
              (change)="saveVolume()"
            >
          </div>
        </div>
        <p class="range-value">{{ values.volume }}</p>
      </div>
    </div>
  </div>
  <div class="video-component-list file-component-list te-scrollable-container"
    rvSpinner="video-file-loader" [rvShowSpinner]="templateEditorService.loadingPresentation || stockLibraryService.loading"
    [ngClass]="{ 'not-empty': selectedFiles.length > 0 }"
  >
    <div (rvSortable)="sortItem($event)" appendTo=".component-container" class="sortable-list">
      @for (file of selectedFiles; track file) {
        <template-editor-file-entry
          class="rv-sortable-item"
          [shown]="showSettingsUI()"
          [entry]="file"
          fileType="video"
          (removeEvent)="removeFileFromList($event)"
          [showGripHandle]="selectedFiles.length > 1">
        </template-editor-file-entry>
      }
    </div>
    <template-editor-empty-file-list fileType="video"
      [hidden]="isUploading || selectedFiles.length !== 0 || templateEditorService.loadingPresentation">
    </template-editor-empty-file-list>
    <basic-uploader uploaderId="video-list-uploader"
      [uploadManager]="uploadManager"
      validType="video"
      [shown]="isUploading">
    </basic-uploader>
  </div>
  <div class="file-component-list-action-button-bar">
    <div class="pt-4 border-top">
      <button id="upload-videos-unsubscribed" class="btn btn-primary btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        <strong>Upload Videos</strong>
      </button>
      <label id="video-list-uploader-label"
        class="btn btn-primary btn-block aligner"
        for="video-list-uploader"
        [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
      >
        <strong>Upload Videos</strong>
      </label>
    </div>
    <div class="mt-3">
      <button id="video-list-storage-button" class="btn btn-default btn-block" (click)="selectFromStorage()" [disabled]="isUploading">
        <strong>Select from Storage</strong>
      </button>
    </div>
    <div class="mt-3" *requireRole="'sa'">
      <button
        class="btn btn-default btn-block"
        (click)="stockLibraryService.showStockLibrary('video', boundAddFile)"
        [disabled]="isUploading"
      >
        <strong>Select from Stock Video Library</strong>
      </button>
    </div>
  </div>
</div>
