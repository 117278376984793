import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyIdModalComponent } from 'src/app/components/scrolling-list/company-id-modal/company-id-modal.component';
import { SchedulePickerModalComponent } from 'src/app/schedules/components/schedule-picker-modal/schedule-picker-modal.component';
import { EditAddressComponent } from '../components/edit-address/edit-address.component';
import { EditRebootTimeComponent } from '../components/edit-reboot-time/edit-reboot-time.component';
import { EditMonitoringComponent } from '../components/edit-monitoring/edit-monitoring.component';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { PlayerLicenseService } from './player-license.service';
import { PlayerActionsService } from './player-actions.service';
import { DisplayControlService } from './display-control.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { DisplayService } from './display.service';
import { ScheduleService } from 'src/app/schedules/services/schedule.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayListOperationsService {

  listOperations = {
    name: 'Display',
    operations: [{
        name: 'Restart Rise Player',
        beforeBatchAction: this._confirmRestart.bind(this),
        actionCall: this.playerActionsService.restartByObject.bind(this.playerActionsService),
        requireRole: 'da'
      },
      {
        name: 'Reboot Media Player',
        beforeBatchAction: this._confirmReboot.bind(this),
        actionCall: this.playerActionsService.rebootByObject.bind(this.playerActionsService),
        requireRole: 'da'
      },
      {
        name: 'License',
        actionCall: (selected) => {
          return this._licenseDisplays(selected.companyId, selected.items);
        },
        beforeBatchAction: this._confirmLicense.bind(this),
        groupBy: 'companyId',
        filter: {
          playerProAuthorized: false
        },
        requireRole: 'da'
      },
      {
        name: 'Assign Schedule',
        beforeBatchAction: this._confirmAssignSchedule.bind(this),
        actionCall: (selected, schedule) => {
          return this.scheduleService.addAllToDistribution(selected.items, schedule);
        },
        groupBy: true,
        requireRole: 'cp'
      },
      {
        name: 'Set Monitoring',
        beforeBatchAction: this._confirmSetMonitoring.bind(this),
        actionCall: this.displayService.applyFields.bind(this.displayService),
        requireRole: 'da'
      },
      {
        name: 'Set Reboot Time',
        beforeBatchAction: this._confirmSetRebootTime.bind(this),
        actionCall: this.displayService.applyFields.bind(this.displayService),
        requireRole: 'da'
      },
      {
        name: 'Set Address',
        beforeBatchAction: this._confirmSetAddress.bind(this),
        actionCall: this.displayService.applyFields.bind(this.displayService),
        requireRole: 'da'
      },
      {
        name: 'Enable Display Control',
        beforeBatchAction: this._confirmEnableDisplayControl.bind(this),
        actionCall: (selected) => {
          return this.displayControlService.enableDisplayControl(selected.id);
        },
        requireRole: 'da'
      },
      {
        name: 'Disable Display Control',
        beforeBatchAction: this._confirmDisableDisplayControl.bind(this),
        actionCall: (selected) => {
          return this.displayControlService.disableDisplayControl(selected.id);
        },
        requireRole: 'da'
      },
      {
        name: 'Move To Company',
        beforeBatchAction: this._confirmMove.bind(this),
        actionCall: (selected, companyId) => {
          if (selected.companyId === companyId) {
            return Promise.resolve();
          }
          return this.displayApiService.move(selected.id, companyId);
        },
        reloadList: true,
        requireRole: 'sa'
      },
      {
        name: 'Export All',
        beforeBatchAction: this._confirmExport.bind(this),
        actionCall: this.displayApiService.export.bind(this.displayApiService),
        groupBy: true //group all
      },
      {
        name: 'Delete',
        actionCall: this.displayService.deleteDisplayByObject.bind(this.displayService),
        requireRole: 'da'
      }
    ]
  };

  constructor(
    private userState: UserState,
    private playerActionsService: PlayerActionsService,
    private playerLicenseService: PlayerLicenseService,
    private currentPlanService: CurrentPlanService,
    private displayService: DisplayService,
    private displayApiService: DisplayApiService,
    private scheduleService: ScheduleService,
    private displayControlService: DisplayControlService,
    private featuresService: FeaturesService,
    private modalService: ModalService,
  ) { }

  private _confirmLicense (selected) {
    const company = this.userState.getCopyOfSelectedCompany(true);
    if (!selected.length) {
      this.modalService.showMessage(
        'Already licensed!',
        'Please select some unlicensed displays to license.');
      return Promise.reject();
    } else if (this.playerLicenseService.getProAvailableLicenseCount() < selected.length) {
      this.currentPlanService.confirmAndPurchase(selected.length - this.playerLicenseService.getProAvailableLicenseCount());
      return Promise.reject();
    } else if (this._hasSubcompanyDisplays(selected) && !company.shareCompanyPlan) {
      this.modalService.showMessage(
        'Unable to share licenses!',
        'Some of the selected displays are for sub-companies, and your current plan does not allow sharing licenses. ' +
        'Please select displays from your company only.');
      return Promise.reject();
    } else {
      return this.modalService.confirm(
        'Assign license?',
        'Do you want to assign licenses to the selected displays?',
        'Yes',
        'No');
    }
  }

  private _licenseDisplays (companyId, displays) {
    const displayIds = displays.map(display => display.id);

    return this.playerLicenseService.licenseDisplaysByCompanyId(companyId, displayIds)
      .then(() => {
        displays.forEach((display) => {
          display.playerProAuthorized = true;
        });
      });
  }

  private _checkLicenses (selectedItems) {
    const notAuthorized = [];
    selectedItems.forEach((display) => {
      if (!display.playerProAuthorized) {
        notAuthorized.push(display.id);
      }
    });
    if (notAuthorized.length > 0) {
      const availableLicenses = this.playerLicenseService.getProAvailableLicenseCount();
      const availableLicensesText = this.currentPlanService.isUnlimitedPlan() ? 'unlimited' : availableLicenses.toString();

      const company = this.userState.getCopyOfSelectedCompany(true);
      if (this._hasSubcompanyDisplays(selectedItems) && !company.shareCompanyPlan) {
        this.modalService.showMessage(
          'Unable to complete bulk action!',
          'This action cannot be performed on unlicensed displays, and your current plan does not allow sharing licenses. ' +
          'Please select displays from your company only.');
        return Promise.reject();
      }

      let baseMessage = notAuthorized.length > 1 ?
        notAuthorized.length +
        ' of your selected displays are not licensed and to perform this action they need to be.' :
        '1 of your selected displays is not licensed and to perform this action it needs to be.';
      baseMessage += ' You have ' + availableLicensesText + ' available license' + (availableLicenses !== 1 ?
        's' : '');

      if (availableLicenses >= notAuthorized.length) {
        return this.modalService.confirm('Almost there!',
          baseMessage + '. Do you want to assign ' + notAuthorized.length + (notAuthorized.length > 1 ?
            ' to these displays?' : ' to this display?'), 'Yes', 'No').then(() => {
            const licenseOperation = this.listOperations.operations.find((o) => {
              return o.name === 'License';
            });
            return (licenseOperation as any).onClick(true);
          });
      } else {
        return this.modalService.confirm('Almost there!',
          baseMessage + ' and you need to subscribe for ' + (notAuthorized.length - availableLicenses) +
          ' more to license ' + (notAuthorized.length > 1 ? 'these displays.' : 'this display.'),
          'Subscribe', 'Cancel').then(() => {
          this.currentPlanService.showPurchaseOptions(notAuthorized.length - availableLicenses);
          return Promise.reject();
        });
      }
    }
    return Promise.resolve();
  }

  private _confirmAssignSchedule (selectedItems) {
    if (this._hasSubcompanyDisplays(selectedItems)) {
      this.modalService.showMessage(
        'Schedule could not be assigned!',
        'Your schedule cannot be assigned to displays that belong to your sub-companies. <br/>Please select displays from your company only.');
      return Promise.reject();
    }
    return this.modalService.showModal(SchedulePickerModalComponent, {
      class: 'madero-style modal-sm'
    });
  }

  private _hasSubcompanyDisplays (selectedItems) {
    const cid = this.userState.getSelectedCompanyId();
    return selectedItems.find((d) => {
      return d.companyId !== cid;
    });
  }

  private _confirmPlayerAction (selectedItems, isRestart) {
    const suffix = selectedItems.length > 1 ? 's' : '';
    const title = isRestart ? 'Restart Rise Player' + suffix + '?' : 'Reboot media player' + suffix + '?';
    let message = isRestart ? 'Rise Player' + suffix + ' will restart' : 'The media player' + suffix +
      ' will reboot';
    message += ' and the content showing on your display' + suffix +
      ' will be interrupted. Do you wish to proceed?';

    return this._checkLicenses(selectedItems).then(() => {
      return this.modalService.confirm(title, message, 'Yes', 'No');
    });
  }

  private _confirmRestart (selectedItems) {
    return this._confirmPlayerAction(selectedItems, true);
  }

  private _confirmReboot (selectedItems) {
    return this._confirmPlayerAction(selectedItems, false);
  }

  private _confirmSetMonitoring (selectedItems) {
    if (this.featuresService.isFeatureAvailable('monitoring')) {
      return this._checkLicenses(selectedItems).then(() => {
        return this.modalService.showBulkEditModal(
          'Set Monitoring',
          {
            monitoringEnabled: true,
            monitoringEmails: null,
            monitoringSchedule: null
          },
          EditMonitoringComponent
        );
      });
    }
    this.featuresService.showUpgradePlanModal('monitoring');
    return Promise.reject();
  }

  private _confirmSetRebootTime (selectedItems) {
    return this._checkLicenses(selectedItems).then(() => {
      return this.modalService.showBulkEditModal(
        'Set Reboot Time',
        {
          restartEnabled: true,
          restartTime: '02:00',
        },
        EditRebootTimeComponent
      );
    });
  }

  private _confirmSetAddress (selectedItems) {
    return this._checkLicenses(selectedItems).then(() => {
      return this.modalService.showBulkEditModal(
        'Set Address',
        {
          useCompanyAddress: false,
          addressDescription: '',
          street: '',
          unit: '',
          city: '',
          country: '',
          province: '',
          postalCode: '',
          timeZoneOffset: null
        },
        EditAddressComponent
      );
    });
  }

  private _confirmEnableDisplayControl (selectedItems) {
    return this._checkLicenses(selectedItems).then(() => {
      if (this.featuresService.isFeatureAvailable('display_control')) {
        return this.modalService.confirm(
          'Enable Display Control?',
          'Do you want to enable display control for the selected displays?',
          'Yes',
          'No');
      }
      this.featuresService.showUpgradePlanModal('display_control');
      return Promise.reject();
    });
  }

  private _confirmDisableDisplayControl (selectedItems) {
    return this._checkLicenses(selectedItems).then(() => {
      if (this.featuresService.isFeatureAvailable('display_control')) {
        return this.modalService.confirm(
          'Disable Display Control?',
          'Do you want to disable display control for the selected displays?',
          'Yes',
          'No');
      }
      this.featuresService.showUpgradePlanModal('display_control');
      return Promise.reject();
    });
  }

  private _confirmExport (selectedItems) {
    if (!this.currentPlanService.isPlanActive() && !this.userState.isRiseAdmin()) {
      this.currentPlanService.showUnlockThisFeatureModal();
      return Promise.reject();
    }
    return this.modalService.confirm('Export displays?',
      'An export file will be prepared and emailed to you at <b>' + this.userState.getUserEmail() +
      '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
      'Export', 'Cancel');
  }

  private _confirmMove (selectedItems) {
    const plural = selectedItems.length > 1 ? 's' : '';
    return this.modalService.showModal(CompanyIdModalComponent, {
      initialState: {
        title: 'Move Selected Display' + plural,
        description: 'The selected display' + plural + ' will be moved to the company ID specified below.'
      }
    });
  }
}
