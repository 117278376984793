<div class="rise-playlist-container attribute-editor-component"
  rvSpinner="rise-playlist-loader" [rvShowSpinner]="playlistComponentService.loading">
  <!-- default view -->
  <div class="attribute-editor-component" >
    <div class="attribute-editor-row">
      @if (!showComponentsDropdown()) {
        <button id="te-playlist-select-templates" class="btn btn-default btn-block" (click)="playlistService.showAddTemplates()">
          Select Presentations
        </button>
      }
      @else {
        <div class="btn-group btn-block">
          <button id="addPlaylistItemButton" type="button" class="btn btn-default btn-block" (click)="showAddPlaylistItem()">Add</button>
        </div>
      }
    </div>
  </div>
  <div class="templates-selector te-scrollable-container" [shown]="playlistService.playlistItems?.length">
    <div (rvSortable)="sortItem($event)" appendTo=".component-container" class="sortable-list">
      @for (item of playlistService.playlistItems; track item; let key = $index) {
        <div class="flex templates-row rv-sortable-item">
          <div class="rv-sortable-handle">
            <streamline-icon class="streamline-component-icon" name="navigation-menu-vertical" width="8" height="24"></streamline-icon>
          </div>
          <div>
            <streamline-icon [shown]="isEmbeddedTemplate(item)" class="streamline-component-icon" name="embedded-template" width="24" height="18"></streamline-icon>
            <streamline-icon [hidden]="isEmbeddedTemplate(item)" class="streamline-component-icon" name="{{ playlistService.getComponentIcon(item) }}" width="24" height="18"></streamline-icon>
          </div>
          <div class="px-2 flex-grow u_text-ellipsis">
            <a [hidden]="isEmbeddedTemplate(item) || item.editing" class="madero-link" [ngClass]="{ 'danger': item.fileMissing }" href="#" (click)="editPlaylistItem(key)">
              <span class="templates-name">{{ playlistService.getComponentName(item) }}</span>
            </a>
            <div [shown]="item.editing" class="mr-2">
              <input #ItemName id="input-item-name-{{ key }}" class="w-100 form-control p-2 h-auto" type="text" [ngModel]="getDisplayName(item)" (blur)="saveItemName(key, $event)" (keyup)="itemNameKeyup(key, $event)">
            </div>
            <div [shown]="isEmbeddedTemplate(item) && !item.editing">
              <span class="templates-name">{{ item.label || item.name }}</span>
              <div class="templates-status templates-status-inline" [ngClass]="{'templates-status-revised' : item.revisionStatusName === 'Revised' || item.removed}">{{ item.revisionStatusName }}</div>
            </div>
          </div>
          <a class="madero-link u_text-ellipsis edit-properties" href="#" (click)="editProperties(key)">{{ durationToText(item) }}</a>
          <button class="btn btn-icon" mat-menu [matMenuTriggerFor]="playlistOptions">
            <streamline-icon class="streamline-component-icon" name="ellipsis" width="16" height="16"></streamline-icon>
          </button>
          <mat-menu #playlistOptions="matMenu" xPosition="before">
            <button class="playlist-item-rename" mat-menu-item (click)="renameItem(key)">
              <streamline-icon class="mr-3 streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
              <span>Edit Label</span>
            </button>
            <button class="playlist-item-copy" mat-menu-item (click)="copyItem(key)">
              <streamline-icon class="mr-3 streamline-component-icon" name="copy" width="16" height="16"></streamline-icon>
              <span>Duplicate</span>
            </button>
            <button class="playlist-item-delete" mat-menu-item (click)="removeItem(key)">
              <streamline-icon class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      }
    </div>
  </div>
</div>
<!-- "edit" view -->
<div class="playlist-item-container attribute-editor-component" style="display:none;"
  rvSpinner="rise-playlist-loader" [rvShowSpinner]="playlistComponentService.loading">
  <div class="form-group has-feedback">
    <div class="attribute-editor-row">
      <label class="control-label" for="te-playlist-item-duration">Duration:</label>
      <div class="flex-row" [shown]="selectedItem['play-until-done-supported']">
        <madero-checkbox name="pud" id="te-playlist-item-pud-true" [ngValue]="selectedItem['play-until-done']" (click)="savePlayUntilDone()"></madero-checkbox>
        <span>
          Play Until Done
        </span>
      </div>
    </div>
    <div class="attribute-editor-row input-group" [shown]="!selectedItem['play-until-done']">
      <input type="number" id="te-playlist-item-duration" class="form-control" [ngClass]="{'duration-input-group' : selectedItem['play-until-done-supported']}" [(ngModel)]="selectedItem.duration" (debounceEvent)="saveProperties()" placeholder="Enter duration">
      <span class="input-group-addon">Seconds</span>
    </div>
    <div class="attribute-editor-row mt-4">
      <label class="control-label" for="te-playlist-item-transition">Transition between playlist items:</label>
      <select id="te-playlist-item-transition" [(ngModel)]="selectedItem['transition-type']" class="form-control" (change)="saveProperties()">
        <option value="normal">No transition</option>
        <option value="fadeIn">Fade in</option>
        <option value="slideFromLeft">Slide from left</option>
        <option value="slideFromRight">Slide from right</option>
        <option value="slideFromTop">Slide from top</option>
        <option value="slideFromBottom">Slide from bottom</option>
        <option value="stripesHorizontal">Stripes horizontal</option>
        <option value="stripesVertical">Stripes vertical</option>
        <option value="zoomIn">Zoom in</option>
      </select>
    </div>
    @if (selectedItem?.timeline) {
      <div class="attribute-editor-row mt-4">
        <label class="control-label">Timeline:</label>
        <timeline-textbox
          [(timelineObject)]="selectedItem.timeline"
          (timelineChange)="saveTimeline()">
        </timeline-textbox>
      </div>
    }
  </div>
</div>
