import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import * as _ from 'lodash';

import { UserState } from 'src/app/ajs-upgraded-providers';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';

import { PresentationsService } from 'src/app/editor/services/presentations.service';

import { environment } from 'src/environments/environment';
import { ScheduleActionsService } from '../../services/schedule-actions.service';
import { PlaylistService } from '../../services/playlist.service';
import { ScheduleService } from '../../services/schedule.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { TemplateApiService } from 'src/app/api/services/template-api.service';

@Component({
  selector: 'schedule-fields',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  templateUrl: './schedule-fields.component.html',
  styleUrls: ['./schedule-fields.component.scss']
})
export class ScheduleFieldsComponent implements OnInit, OnDestroy {

  originalChangeDate = this.scheduleService.schedule.changeDate;
  applyTimeline = false;
  freeDisplays = [];
  licenseRequired = false;
  isMobile = this.presentationUtils.isMobileBrowser();
  isAssignedPublisher = this.userState.isAssignedPublisher();
  appleTVDisplays = false;
  nonAppleTVPresentations = false;

  scheduleForm: NgForm;

  _sanitizedPreviewUrl;
  _previewUrl;
  get sanitizedPreviewUrl() {
    if (!this.scheduleService.schedule) {
      return null;
    }
    let previewUrl = environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', this.scheduleService.schedule.id) +
      '&env=apps_schedule';

    if (!this.applyTimeline) {
      previewUrl += '&applyTimeline=false';
    }

    if (this.originalChangeDate !== this.scheduleService.schedule.changeDate) {
      previewUrl += '&dataSource=core&changeDate=' + this.scheduleService.schedule.changeDate;
    }

    if (previewUrl !== this._previewUrl || !this._sanitizedPreviewUrl) {
      this._previewUrl = previewUrl;
      this._sanitizedPreviewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(previewUrl);
    }

    return this._sanitizedPreviewUrl;
  }

  private playlistSubjectSubscription;
  private scheduleCreatedSubscription;
  private scheduleUpdatedSubscription;

  constructor(
    private domSanitizer: DomSanitizer,
    public userState: UserState,
    private modalService: ModalService,
    public scheduleService: ScheduleService,
    private schedulePlaylistService: PlaylistService,
    private scheduleActionsService: ScheduleActionsService,
    private presentationsService: PresentationsService,
    public playerLicenseService: PlayerLicenseService,
    private presentationUtils: PresentationUtilsService,
    private trackerService: TrackerService,
    private controlContainer: ControlContainer,
    private broadcaster: BroadcasterService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.scheduleForm = <NgForm>this.controlContainer;

    this.playlistSubjectSubscription = this.schedulePlaylistService.playlistSubject.subscribe(playlist => {
      this.runScheduleChecks(false);
    });

    this.scheduleCreatedSubscription = this.broadcaster.on('scheduleCreated', () => {
      this.runScheduleChecks();
    });

    this.scheduleUpdatedSubscription = this.broadcaster.on('scheduleUpdated', () => {
      this.runScheduleChecks();
    });

    this.runScheduleChecks();
  }

  ngOnDestroy(): void {
    this.playlistSubjectSubscription.unsubscribe();

    this.scheduleCreatedSubscription.unsubscribe();
    this.scheduleUpdatedSubscription.unsubscribe();
  }

  runAppleChecks(checkDisplays) {
    let promise = checkDisplays ? this.checkAppleTVDisplays() : Promise.resolve();

    return promise.then(() => {
      if (this.appleTVDisplays) {
        return this.checkNonApplePresentations();
      } else {
        return Promise.resolve();
      }
    });
  }

  runScheduleChecks(checkDisplays = true) {
    return Promise.all([
      this.checkLicenseRequired(),
      this.runAppleChecks(checkDisplays)
    ]).finally(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  checkLicenseRequired() {
    if (this.scheduleService.requiresLicense()) {
      this.playerLicenseService.apiError = '';

      return this.scheduleService.hasFreeDisplays()
        .then((result) => {
          this.freeDisplays = result;
          this.licenseRequired = this.scheduleService.requiresLicense();
        });
    } else {
      return Promise.resolve();
    }
  }

  checkAppleTVDisplays() {
    return this.scheduleService.checkAppleTvDisplays()
      .then((result) => {
        this.appleTVDisplays = result;
      });
  }

  checkNonApplePresentations() {
    return this.schedulePlaylistService.checkAppleTVCompatibleStatus()
      .then((isCompatible) => {
        this.nonAppleTVPresentations = !isCompatible;
      });
  }

  distributeToSubcompaniesChange() {
    this.trackerService.scheduleEvent('Distribute To Subcompanies Changed', this.scheduleService.schedule.scheduleId, this.scheduleService.schedule.scheduleName, {
      distributeToSubcompanies: this.scheduleService.schedule.distributeToSubcompanies
    });
  }

  scheduleTypeChange() {
    this.trackerService.scheduleEvent('Schedule Type Changed', this.scheduleService.schedule.scheduleId, this.scheduleService.schedule.scheduleName, {
      scheduleType: this.scheduleService.schedule.scheduleType
    });
  }

  licenseFreeDisplays() {
    this.playerLicenseService.confirmAndLicense(this.freeDisplays)
      .then(() => {
        this.freeDisplays = [];
      });
  }

  openPlaylistModal(playlistItem) {
    this.scheduleService.openPlaylistModal(playlistItem);
  }

  addUrlItem() {
    this.openPlaylistModal(this.schedulePlaylistService.getNewUrlItem());
  }

  addPresentationItem() {
    this.presentationsService.openPresentationMultiSelector()
      .then((presentations) => {
        if (presentations && presentations.length === 1) {
          this.openPlaylistModal(this.schedulePlaylistService.newPresentationItem(presentations[0]));
        } else {
          this.schedulePlaylistService.addPresentationItems(presentations);
        }
      });
  }

  confirmDelete() {
    this.modalService.confirm('Delete Schedule?',
        'Are you sure you want to delete this Schedule?',
        'Yes', 'No')
      .then(() => {
        this.scheduleService.deleteSchedule();
      });
  }

  assignUsersVisible() {
    return this.userState.hasRole('cp') && (this.userState.isK12Customer() || this.userState.isHigherEdCustomer());
  }

  assignUsers() {
    this.scheduleActionsService.assignUsersToSchedule(
      this.scheduleService.schedule && this.scheduleService.schedule.assignedUsers ? _.cloneDeep(this.scheduleService.schedule.assignedUsers) : []
    ).then((users) => {
      if (!_.isEqual(this.scheduleService.schedule.assignedUsers, users)) {
        this.scheduleService.schedule.assignedUsers = users;
        this.trackerService.scheduleEvent('Schedule Assigned Users Changed', this.scheduleService.schedule.scheduleId, this.scheduleService.schedule.scheduleName, {
          assignedUsers: users.length
        });
      }
    }).catch(() => {});
  }

}
