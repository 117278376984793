import * as _ from 'lodash';

import { Inject, Injectable } from '@angular/core';

import { StoreService, UserState } from 'src/app/ajs-upgraded-providers';

import { PlansService } from 'src/app/components/plans/plans.service';
import { AddressUtilsService } from 'src/app/shared/services/address-utils.service';
import { ContactUtilsService } from 'src/app/shared/services/contact-utils.service';
import { ReloadSelectedCompanyService } from 'src/app/components/userstate/reload-selected-company.service';
import { CreditCardService } from './credit-card.service';
import { AddPaymentSourceService } from 'src/app/billing/services/add-payment-source.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';

export type PurchasePlan = {
  name: string;
  productId: string;
  productCode: string;
  licenses: number;
  isMonthly?: boolean;
  monthly?: { billAmount: number };
  yearly?: { billAmount: number };
};

export type PurchaseDetails = {
  volumePlan?: PurchasePlan;
  unlimitedPlan?: PurchasePlan;
  couponCode?: string;
  billingAddress?: any;
  contact?: any;
  estimate?: any;
  checkoutError?: any;
  reloadingCompany?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  public loading = false;
  public purchase: PurchaseDetails;

  private reloadCallback;

  constructor(
    @Inject('$rootScope') private $rootScope:any,
    private userState: UserState,
    private reloadSelectedCompany: ReloadSelectedCompanyService,
    private storeService: StoreService,
    private addressUtils: AddressUtilsService,
    private contactUtils: ContactUtilsService,
    private creditCardService: CreditCardService,
    private addPaymentSourceService: AddPaymentSourceService,
    private trackerService: TrackerService,
    private plansService: PlansService) {

    }

    _setupVolumePlan(licenses, isMonthly, productCode?) {
      const volumePlan = productCode
        ? this.plansService.getPlan(productCode)
        : this.plansService.getAdvancedPlan(this.userState.isDiscountCustomer());
      const period = !isMonthly ? 'Annual' : 'Monthly';
      const planName = licenses > 1 ? volumePlan.name : volumePlan.name.replace('Licenses', 'License');
      const plan = {
        name: licenses + ' ' + planName + ' (' + period + ')',
        productId: volumePlan.productId,
        productCode: volumePlan.productCode,
        licenses: licenses,
        isMonthly: isMonthly,
        yearly: {
          billAmount: volumePlan.yearly.billAmount
        },
        monthly: {
          billAmount: volumePlan.monthly.billAmount
        }
      };
      this.purchase.volumePlan = plan;
    }

    init() {
      this.purchase = {};
      this._setupVolumePlan(5, false);
      this.purchase.couponCode = '';

      this.purchase.billingAddress = this.addressUtils.copyAddress(this.userState.getCopyOfSelectedCompany());

      this.purchase.contact = this.contactUtils.copyContactObj(this.userState.getCopyOfProfile());

      this.purchase.estimate = {};

      return this.creditCardService.initPaymentMethods(false)
        .finally( () => {
          this.creditCardService.paymentMethods.paymentMethod = 'card';
          this.creditCardService.paymentMethods.newCreditCard.billingAddress = this.purchase.billingAddress;

          var invoiceDate = new Date();
          invoiceDate.setDate(invoiceDate.getDate() + 30);
          this.creditCardService.paymentMethods.invoiceDate = invoiceDate;
        });

    }

    pickUnlimitedPlan(licenseCount: number) {
      var unlimitedPlan = this.plansService.getUnlimitedPlan();
      var plan = {
        name: licenseCount + ' ' + (licenseCount > 1 ? unlimitedPlan.name : unlimitedPlan.name.replace('Licenses', 'License')),
        productId: unlimitedPlan.productId,
        productCode: unlimitedPlan.productCode,
        licenses: licenseCount,
        isMonthly: false,
        yearly: {
          billAmount: unlimitedPlan.yearly.billAmount
        }
      };
      this.purchase.unlimitedPlan = plan;
      this.trackerService.productAddedEvent(this.purchase.unlimitedPlan);
    }

    clearUnlimitedPlan() {
      if (this.purchase.unlimitedPlan?.licenses) {
        this.purchase.unlimitedPlan.licenses = 0;
      }
    }

    pickVolumePlan(licenses, isMonthly, total, productCode?) {
      this._setupVolumePlan(licenses, isMonthly, productCode);
      if (isMonthly) {
        this.purchase.volumePlan.monthly.billAmount = total;
      } else {
        this.purchase.volumePlan.yearly.billAmount = total;
      }
      this.trackerService.productAddedEvent(this.purchase.volumePlan);
    }

    clearVolumePlan() {
      if (this.purchase.volumePlan?.licenses) {
        this.purchase.volumePlan.licenses = 0;
      }
    }

    preparePaymentIntent() {
      var paymentMethods = this.creditCardService.paymentMethods;

      if (paymentMethods.paymentMethod === 'invoice') {
        return Promise.resolve();
      } else if (paymentMethods.paymentMethod === 'card') {
        this.loading = true;

        if (this._getOrderItems().length > 1) {

          return this.addPaymentSourceService.validateAndPreparePaymentSource()
            .catch( (error) => {
              this.purchase.checkoutError = error.message || 'Something went wrong, please retry';
              return Promise.reject(error);
            })
            .finally( () => {
              this.loading = false;
            });
        } else {

          var jsonData = this._getOrderAsJson();

          return this.storeService.preparePurchase(jsonData)
            .then( (response) => {
              if (response.error) {
                this.purchase.checkoutError = response.error;
                return Promise.reject(response.error);
              } else {
                paymentMethods.intentResponse = response;
                if (response.authenticationRequired) {
                  return this.creditCardService.handleCardAction(response.intentSecret);
                } else {
                  return Promise.resolve();
                }
              }
            })
            .catch( (error) => {
              this.purchase.checkoutError = error.message || 'Something went wrong, please retry';
              return Promise.reject(error);
            })
            .finally( () => {
              this.loading = false;
            });
          }
      }
    }

    validatePaymentMethod() {
      this.purchase.checkoutError = null;

      if (this.creditCardService.paymentMethods.paymentMethod === 'invoice') {
        // TODO: Check Invoice credit (?)
        return Promise.resolve();
      } else if (this.creditCardService.paymentMethods.paymentMethod === 'card') {
        this.loading = true;

        return this.creditCardService.validatePaymentMethod()
          .finally( () => {
            this.loading = false;
          });
      }
    }

    private _getBillingPeriod(plan: PurchasePlan): string {
      return plan.isMonthly ? '01m' : '01y';
    }

    private _getCurrency(): string {
      return (this.purchase.billingAddress.country === 'CA') ? 'cad' : 'usd';
    }

    private _getChargebeePlanId(plan: PurchasePlan): string {
      return plan ? plan.productCode + '-' + this._getCurrency() + this._getBillingPeriod(plan) : undefined;
    }

    private _getTrackingProperties(plan: PurchasePlan) {
      return {
        planId: this._getChargebeePlanId(plan),
        displaysCount: plan.licenses,
        paymentTerm: plan.isMonthly ? 'monthly' : 'yearly',
        paymentMethod: this.creditCardService.paymentMethods.paymentMethod,
        discount: this.purchase.estimate.couponAmount / 100,
        subscriptionPlan: plan.name,
        currency: this.purchase.estimate.currency,
        revenueTotal: this.purchase.estimate.total / 100
      };
    }

    getEstimate() {
      this.loading = true;

      return this.storeService.estimatePurchase(this._getEstimateAsJson())
        .then(results => {
          var estimate: any = {
            taxes: [],
            volumeSubtotal: 0,
            unlimitedSubtotal: 0,
            total: 0,
            subTotal: 0,
            coupons: [],
            couponAmount: 0,
            totalTax: 0,
            shippingTotal: 0 // shippingTotal was never updated in ChargebeeAPI
          };
          let taxes = {};
          let coupons = {};

          results.items.forEach(result => {
            const invoiceEstimate = result.next_invoice_estimate || result.invoice_estimate;

            for (let tax of invoiceEstimate.taxes || []) {
              if (!taxes[tax.name]) {
                taxes[tax.name] = _.cloneDeep(tax);
                taxes[tax.name].amount = 0;
              }

              taxes[tax.name].amount += tax.amount;
              estimate.totalTax += tax.amount;
            }

            for (let coupon of invoiceEstimate.discounts || []) {
              if (!coupons[coupon.entity_id]) {
                coupons[coupon.entity_id] = _.cloneDeep(coupon);
                coupons[coupon.entity_id].amount = 0;
              }

              coupons[coupon.entity_id].amount += coupon.amount;
              estimate.couponAmount += coupon.amount;
            }

            estimate.currency = this._getCurrency();
            estimate.taxesCalculated = true;
            estimate.total += invoiceEstimate.total;
            estimate.subTotal += invoiceEstimate.sub_total;

            if (invoiceEstimate.line_items && invoiceEstimate.line_items[0].entity_id === this._getChargebeePlanId(this.purchase.volumePlan)) {
              estimate.volumeSubtotal += invoiceEstimate.sub_total;
            }
            if (invoiceEstimate.line_items && invoiceEstimate.line_items[0].entity_id === this._getChargebeePlanId(this.purchase.unlimitedPlan)) {
              estimate.unlimitedSubtotal += invoiceEstimate.sub_total;
            }
          });

          estimate.taxes = Object.values(taxes);
          estimate.coupons = Object.values(coupons);

          this.purchase.estimate = estimate;

          if (this.purchase.volumePlan?.licenses) {
            this.trackerService.placeOrderClickedEvent(this._getTrackingProperties(this.purchase.volumePlan));
          }
          if (this.purchase.unlimitedPlan?.licenses) {
            this.trackerService.placeOrderClickedEvent(this._getTrackingProperties(this.purchase.unlimitedPlan));
          }
        })
        .catch( (result) => {
          this.purchase.estimate.estimateError = result && result.message ? result.message :
            'An unexpected error has occurred. Please try again.';
        })
        .finally( () => {
          this.loading = false;
        });
    }

    private _getOrderItems() {
      const items = [];
      if (this.purchase.volumePlan?.licenses) {
        items.push({
          id: this._getChargebeePlanId(this.purchase.volumePlan),
          qty: this.purchase.volumePlan.licenses
        });
      }
      if (this.purchase.unlimitedPlan?.licenses) {
        items.push({
          id: this._getChargebeePlanId(this.purchase.unlimitedPlan),
          qty: this.purchase.unlimitedPlan.licenses
        });
      }
      return items;
    }

    private _getEstimateAsJson() {
      const obj = {
        billTo: this.addressUtils.copyAddress(this.purchase.billingAddress),
        shipTo: this.addressUtils.copyAddress(this.purchase.billingAddress),
        couponCode: this.purchase.couponCode,
        items: this._getOrderItems()
      };

      return JSON.stringify(obj);
    }

    private _getOrderAsJson() {
      //clean up items
      var paymentMethods = this.creditCardService.paymentMethods;
      const items = this._getOrderItems();
      var card = paymentMethods.selectedCard;
      var intentId = paymentMethods.intentResponse ? paymentMethods.intentResponse.intentId : null;
      var cardData: any = paymentMethods.paymentMethod === 'invoice' ? null : {
        cardId: card.id,
        intentId: items.length > 1 ? null : intentId,
        setupIntentId: items.length > 1 ? intentId : null,
        isDefault: card.isDefault ? true : false
      };

      var obj = {
        billTo: this.addressUtils.copyAddress(this.purchase.billingAddress),
        shipTo: this.addressUtils.copyAddress(this.purchase.billingAddress),
        couponCode: this.purchase.couponCode,
        items,
        purchaseOrderNumber: paymentMethods.purchaseOrderNumber,
        card: cardData,
        paymentMethodId: this.creditCardService.getPaymentMethodId(),
        invoiceImmediately: items.length === 1,
        useUnbilledCharges: items.length > 1
      };

      return JSON.stringify(obj);
    }

    _reloadPlan() {

      this.reloadSelectedCompany.reloadPlan(this.purchase.volumePlan.productCode, this.purchase.volumePlan.licenses)
        .then( () => {
          this.$rootScope.$emit('risevision.company.planStarted');
        })
        .catch( (err) => {
          console.debug('Failed to reload company', err);
        })
        .finally( () => {
          this.purchase.reloadingCompany = false;

          if (this.reloadCallback) {
            this.reloadCallback();
          }
        });
    }

    completePayment() {
      var jsonData = this._getOrderAsJson();

      this.purchase.checkoutError = null;
      this.loading = true;
      this.purchase.reloadingCompany = true;

      return this.storeService.purchase(jsonData)
        .then( () => {
          if (this.purchase.volumePlan?.licenses) {
            this.trackerService.orderPayNowClickedEvent(this._getTrackingProperties(this.purchase.volumePlan));
          }
          if (this.purchase.unlimitedPlan?.licenses) {
            this.trackerService.orderPayNowClickedEvent(this._getTrackingProperties(this.purchase.unlimitedPlan));
          }

          this._reloadPlan();
        })
        .catch( (result) => {
          this.purchase.checkoutError = result && result.message ? result.message :
            'There was an unknown error with the payment.';
        })
        .finally( () => {
          this.loading = false;
        });
    }

    onCompanyReload(reloadCallback) {
      this.reloadCallback = reloadCallback;
    }

}