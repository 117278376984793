import { Component } from '@angular/core';

import { PaymentSourcesService } from 'src/app/purchase/services/payment-sources.service';
import { TaxExemptionService } from 'src/app/purchase/services/tax-exemption.service';

import { PlansService } from 'src/app/components/plans/plans.service';

import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';
import { SubscriptionService } from '../../services/subscription.service';
import { BillingUtilsService } from '../../services/billing-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {

  public company = this.companyStateService.getCopyOfSelectedCompany();
  public canAccessUnlimitedPlan = this.companyStateService.isK12Customer();

  changePoNumber = this.subscriptionService.changePoNumber.bind(this.subscriptionService);

  get itemSubscription () {
    return this.subscriptionService.getItemSubscription();
  }

  get subscriptionPlan () {
    return this.itemSubscription && this.itemSubscription.plan_id
      ? this.plansService.getPlanById(this.itemSubscription.plan_id)
      : undefined;
  }

  get planType () {
    if (this.subscriptionPlan) {
      if (this.plansService.isBasicPlan(this.subscriptionPlan)) {
        return 'basic';
      }
      if (this.plansService.isAdvancedPlan(this.subscriptionPlan)) {
        return 'advanced';
      }
      if (this.plansService.isEnterprisePlan(this.subscriptionPlan)) {
        return 'enterprise';
      }
      if (this.plansService.isUnlimitedPlan(this.subscriptionPlan)) {
        return 'unlimited';
      }
    }
    return '';
  }

  get displayCount () {
    return this.itemSubscription.plan_quantity;
  }

  constructor(private companyStateService: CompanyStateService,
    public subscriptionService: SubscriptionService,
    public paymentSourcesFactory: PaymentSourcesService,
    public taxExemptionFactory: TaxExemptionService,
    private plansService: PlansService,
    public billingUtils: BillingUtilsService,
    public helpWidgetFactory: HelpWidgetFactory) {

      this.paymentSourcesFactory.init();
      this.taxExemptionFactory.init();

  }

  isDisplayLicensePlan(subscription?) {
    if (!subscription) {
      return false;
    }

    var plan = this.plansService.getPlanById(subscription.plan_id);

    return this.plansService.isDisplayLicensePlan(plan);
  }

  isVolumePlan(subscription?) {
    if (!subscription) {
      return false;
    }

    var plan = this.plansService.getPlanById(subscription.plan_id);

    return this.plansService.isVolumePlan(plan);
  }

  getPlanName(subscription?) {
    if (!subscription) {
      return '';
    }

    var plan = this.plansService.getPlanById(subscription.plan_id);

    return plan && (plan.name + ' Plan') || subscription.plan_id;
  }

}