import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DoBootstrap, NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { CommonHeaderModule } from './common-header/common-header.module';
import { BillingModule } from './billing/billing.module';
import { EditorModule } from './editor/editor.module';
import { DisplaysModule } from './displays/displays.module';
import { StyleGuideModule } from './style-guide/style-guide.module';
import { $lazyLoadProvider, downloadServiceProvider, exceptionHandlerProvider, gapiLoaderProvider, riseAPILoaderProvider, urlStateServiceProvider } from './ajs-upgraded-providers';
import { bigQueryLoggingProvider } from './ajs-upgraded-providers';
import { analyticsFactoryProvider, fileDownloaderProvider, proxyFileDownloaderProvider, processErrorCodeProvider, storeServiceProvider, userAuthFactoryProvider, userStateProvider, scheduleFactoryProvider, storageAPILoaderProvider } from './ajs-upgraded-providers';
import { scrollingListServiceProvider, canAccessAppsProvider, blobDownloaderFactoryProvider } from './ajs-upgraded-providers';
import { helpWidgetFactoryProvider, countriesProvider, insecureUrlProvider, coreAPILoaderProvider, outsideClickHandlerProvider, companyAssetsServiceProvider } from './ajs-upgraded-providers';
import { googleAuthFactoryProvider } from './ajs-upgraded-providers';
import { storeAPILoaderProvider } from './ajs-upgraded-providers';
import { storeAuthorizationProvider } from './ajs-upgraded-providers';
import { gadgetsApiProvider } from './ajs-upgraded-providers';
import { TemplateEditorModule } from './template-editor/template-editor.module';
import { PurchaseModule } from './purchase/purchase.module';
import { environment } from 'src/environments/environment';
import * as angular from 'angular';
import { ComponentsModule } from './components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { setTheme } from 'ngx-bootstrap/utils';
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import { StorageModule } from './storage/storage.module';
import { AuthModule } from './auth/auth.module';
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { SchedulesModule } from './schedules/schedules.module';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    HttpClientModule,
    ModalModule.forRoot(),
    UIRouterUpgradeModule.forRoot(),
    SharedModule,
    BillingModule,
    CommonHeaderModule,
    ComponentsModule,
    BillingModule,
    EditorModule,
    DisplaysModule,
    PurchaseModule,
    TemplateEditorModule,
    StorageModule,
    AuthModule,
    SchedulesModule,
    StyleGuideModule
  ],
  declarations: [],
  providers: [
    $lazyLoadProvider,
    exceptionHandlerProvider,
    analyticsFactoryProvider,
    bigQueryLoggingProvider,
    canAccessAppsProvider,
    fileDownloaderProvider,
    proxyFileDownloaderProvider,
    blobDownloaderFactoryProvider,
    helpWidgetFactoryProvider,
    processErrorCodeProvider,
    scheduleFactoryProvider,
    scrollingListServiceProvider,
    gapiLoaderProvider,
    storageAPILoaderProvider,
    storeAPILoaderProvider,
    coreAPILoaderProvider,
    riseAPILoaderProvider,
    storeServiceProvider,
    downloadServiceProvider,
    gadgetsApiProvider,
    storeAuthorizationProvider,
    googleAuthFactoryProvider,
    userAuthFactoryProvider,
    urlStateServiceProvider,
    userStateProvider,
    insecureUrlProvider,
    countriesProvider,
    outsideClickHandlerProvider,
    companyAssetsServiceProvider,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig }
  ],
  bootstrap: []
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {
    setTheme('bs3');
  }

  ngDoBootstrap() {
  	console.log('Bootstraping AngularJS');
    this.upgrade.bootstrap(document.documentElement, ['risevision.apps']);
  }
}

angular.module('risevision.common.config')
  .value('environment', environment);
