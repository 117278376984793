import { Component, OnInit } from '@angular/core';
import { AlertsService } from '../../services/alerts.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TemplatesService } from 'src/app/editor/services/templates.service';
import { TemplateApiService } from 'src/app/api/services/template-api.service';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  ALERTS_WS_URL = environment.ALERTS_WS_URL;
  showFilters: boolean = false;
  showButtons: boolean = false;
  featureAvailable: boolean;
  templates: any[] = [];

  WARNING_NOT_SUBSCRIBED = 'This feature requires an active subscription.';
  WARNING_NOT_AVAILABLE = 'This feature is not available on your current plan.';
  WARNING_MISSING_TEMPLATE = 'Please select an alerts template.';

  _alertsOn = false;
  get alertsOn(): boolean {
    return this._alertsOn;
  }
  set alertsOn(value: boolean) {
    this._alertsOn = value;
    this._updateEnabled();
  }

  _termsAccepted = false;
  get termsAccepted(): boolean {
    return this._termsAccepted;
  }
  set termsAccepted(value: boolean) {
    this._termsAccepted = value;
    this._updateEnabled();
  }

  _distributeToAll = true;
  get distributeToAll(): boolean {
    return this._distributeToAll;
  }
  set distributeToAll(value: boolean) {
    this._distributeToAll = value;
    if (value) {
      this.alertsService.alertSettings.distribution = [];
    } else {
      this.alertsService.alertSettings.distribution = this.alertsService.alertSettings.distribution || [];
    }
  }

  get alertUrl() {
    return this.ALERTS_WS_URL + '?id=' + this.alertsService.alertKey;
  }

  allowedSendersCsv = '';
  allowedHandlingCodesCsv = '';
  allowedEventCodesCsv = '';

  constructor(
    public alertsService: AlertsService,
    private featuresService: FeaturesService,
    private modalService: ModalService,
    private templatesService: TemplatesService,
    private templateApiService: TemplateApiService,
    public userState: UserState
  ) { }

  ngOnInit(): void {
    this.alertsService.loadSettings();
    this.featureAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_ALERTS);
    if (this.alertsService.alertSettings?.enabled) {
      this._termsAccepted = true;
      this._alertsOn = true;
      this.showButtons = true;
    }
    if (this.alertsService.alertSettings?.distribution) {
      this.distributeToAll = false;
    }
    this.allowedSendersCsv = this.alertsService.alertSettings.allowedSenders? this.alertsService.alertSettings.allowedSenders.join(',') : '';
    this.allowedHandlingCodesCsv = this.alertsService.alertSettings.allowedHandlingCodes ? this.alertsService.alertSettings.allowedHandlingCodes.join(',') : '';
    this.allowedEventCodesCsv = this.alertsService.alertSettings.allowedEventCodes ? this.alertsService.alertSettings.allowedEventCodes.join(',') : '';

    if (this.alertsOn) {
      this._loadTemplates();
    }
  }

  private _setDefaultTemplate() {
    if (
      !this.alertsService.alertSettings.templateProductCode &&
      !this.alertsService.alertSettings.presentationId &&
      this.templates.length
    ) {
      this.alertsService.alertSettings.templateProductCode = this.templates[0].productCode;
    }
  }

  private _loadTemplates() {
    if (this.templatesService.capAlerts?.length) {
      this.templates = this.templatesService.capAlerts;
      this._setDefaultTemplate();
    } else {
      this.alertsService.savingAlerts = true;
      this.templateApiService.listTemplates({
        count: 10,
        filter: 'templateContentTypes:=Common Alert Protocol'
      }).then((result) => {
        this.templatesService.capAlerts = result.items;
        this.templates = result.items;
        this._setDefaultTemplate();
      }).finally(() => {
        setTimeout(() => {
          this.alertsService.savingAlerts = false;
        });
      });
    }
  }

  private _updateEnabled () {
    if (this._termsAccepted && this._alertsOn) {
      this.alertsService.alertSettings.enabled = true;
    } else {
      this.alertsService.alertSettings.enabled = false;
    }
  }

  toggleAlerts() {
    this.alertsOn = !this.alertsOn;
    if (this.alertsOn && !this.templates.length) {
      this._loadTemplates();
    }
  }

  showUpgradePlanModal() {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_ALERTS);
  }

  parseCsvList(csv: string, fieldName: string) {
    if (!this.alertsService.alertSettings[fieldName]) {
      this.alertsService.alertSettings[fieldName] = [];
    }

    const list = this.alertsService.alertSettings[fieldName];
    const items = csv.trim() === '' ? [] : csv.split(',');
    list.splice(0, list.length);
    items.forEach((item) => {
      list.push(item.trim());
    });
  }

  getCheckboxList(fieldName: string, key: string) {
    return this.alertsService.alertSettings[fieldName] && (this.alertsService.alertSettings[fieldName].indexOf(key) >= 0);
  }

  setCheckboxList(fieldName: string, key: string, value?: boolean) {

    if (!this.alertsService.alertSettings[fieldName]) {
      this.alertsService.alertSettings[fieldName] = [];
    }

    const fieldValue = this.alertsService.alertSettings[fieldName];
    const index = fieldValue.indexOf(key);
    if (value === undefined && index < 0 || value) {
      fieldValue.push(key);
    } else {
      fieldValue.splice(index, 1);
    }
  }

  saveSettings() {
    this.alertsService.save().then(() => {
      this.showButtons = !!this.alertsService.alertSettings?.enabled;
    });
  }

  copyAlertUrl() {
    window.navigator.clipboard.writeText(this.alertUrl);
    this.modalService.showMessage('Web Service URL Copied', 'The URL has been copied to your clipboard.');
  }

  resetAlertUrl() {
    if (this.featureAvailable) {
      this.modalService.confirm(
        'Reset Web Service URL?',
        'If you reset your Web Service URL, you will need to provide the new URL to your CAP Alerts provider, otherwise your alerts will stop working.',
        'I Understand',
        'Cancel'
      ).then(() => {
        this.alertsService.regenerateAlertKey();
      }).catch(() => {});
    }
  }

  setTemplate(productCode: string | boolean) {
    if (productCode === true) {
      this.alertsService.alertSettings.templateProductCode = '';
      this.alertsService.changePresentation();
    } else {
      this.alertsService.alertSettings.presentationId = '';
      this.alertsService.alertSettings.presentationName = '';
      if (productCode) {
        this.alertsService.alertSettings.templateProductCode = productCode;
      } else if (this.templates?.length) {
        this.alertsService.alertSettings.templateProductCode = this.templates[0].productCode;
      }
    }
  }
}
