import { Injectable } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { PlansService } from 'src/app/components/plans/plans.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAccessService {

  constructor(
    private currentPlanService: CurrentPlanService,
    private plansService: PlansService,
    private userState: UserState
  ) { }

  get isEnterpriseOrUnlimited(): boolean {
    const plan = this.currentPlanService.currentPlan;
    return this.plansService.isUnlimitedPlan(plan) || this.plansService.isEnterprisePlan(plan);
  }

  get accessCompanyLicenses(): boolean {
    const company = this.userState.getCopyOfSelectedCompany();

    return (this.userState.hasRole('sa') || this.userState.hasRole('ua')) &&
      this.currentPlanService.isPlanActive() &&
      (this.isEnterpriseOrUnlimited || this.currentPlanService.isOnTrial()) &&
      !this.currentPlanService.isParentPlan() &&
      (!company.allocatedFromCompanyId || company.allocatedFromCompanyId === company.id);
  }
}
