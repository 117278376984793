import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';

@Directive({
  selector: '[requireRole]'
})
export class RequireRoleDirective {

  rendered = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private userState: UserState
  ) { }

  @Input() set requireRole(roles: string) {
    if (this.userState.hasPermission(roles) && !this.rendered) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);

      this.rendered = true;
    } else if (!this.userState.hasPermission(roles) && this.rendered) {
      this.viewContainerRef.clear();

      this.rendered = false;
    }
  }

}
