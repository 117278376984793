import { Injectable } from '@angular/core';

import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class ObjectHelperService {

  constructor() { }

  follow (source) {
    var Follower = function () {};
    Follower.prototype = source;
    return new Follower();
  };

  clearObj (obj) {
    for (var member in obj) {
      delete obj[member];
    }
  };

  clearAndCopy (src, dest) {
    this.clearObj(dest);
    angular.extend(dest, src);
  };

}
