import { ChangeDetectorRef, Component, NgZone } from '@angular/core';

import { ScrollingListService, UserState } from 'src/app/ajs-upgraded-providers';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyIdModalComponent } from 'src/app/components/scrolling-list/company-id-modal/company-id-modal.component';
import { UsernamePipe } from 'src/app/shared/pipes/username.pipe';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent {

  search: any = {
    sortBy: 'changeDate',
    reverse: true,
    name: 'Schedules',
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  listOperations = {
    name: 'Schedule',
    operations: [
      {
        name: 'Copy',
        beforeBatchAction: (selected) => {
          const plural = selected.length > 1 ? 's' : '';
          return this.modalService.showModal(CompanyIdModalComponent, {
            initialState: {
              title: 'Copy Selected Schedule' + plural,
              description: 'A copy of ' + (plural ? 'each of ' : '') + 'the selected schedule' + plural +
                ' and any associated presentations will be added to the Company ID specified below.'
            }
          });
        },
        actionCall: (schedule?, companyId?) => {
          if (schedule && schedule.companyId === companyId) {
            this.listOperations.operations[0].reloadList = true;
          }
          return this.scheduleApiService.copy(schedule.id, companyId);
        },
        reloadList: false,
        requireRole: 'sa'
      },
      {
        name: 'Delete',
        actionCall: (schedule?) => {
          return this.scheduleService.deleteScheduleByObject(schedule);
        },
        requireRole: 'cp'
      },
      {
        name: 'Export',
        beforeBatchAction: (selected) => {
          return this.modalService.confirm('Export schedules?',
            'An export file will be prepared and emailed to you at <b>' + this.userState.getUserEmail() +
            '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
            'Export', 'Cancel');
        },
        actionCall: this.scheduleApiService.export.bind(this.scheduleApiService),
        groupBy: true,
        requireRole: 'cp'
      }
    ]
  };
  schedules = this.ScrollingListService(this.scheduleApiService.list.bind(this.scheduleApiService), this.search, this.listOperations);

  filterConfig = {
    placeholder: 'Search Schedules'
  };

  assignedToUsers: string[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ScrollingListService: ScrollingListService,
    private scheduleApiService: ScheduleApiService,
    public scheduleService: ScheduleService,
    private modalService: ModalService,
    public userState: UserState,
    private usernamePipe: UsernamePipe,
    private ngZone: NgZone)
  { }

  popoverText(assignedUsers: string[]) {
    if (assignedUsers.length > 1) {
      this.assignedToUsers = assignedUsers
        .slice(1)
        .map(user => user ? this.usernamePipe.transform(user) : '');
    } else {
      this.assignedToUsers = [];
    }

    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }

}
