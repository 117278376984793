import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';

import { UserState, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { UserApiService } from 'src/app/api/services/user-api.service';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent {

  user: any = {};
  loading = false;
  formError = null;
  apiError = null;

  isUserAdmin = this.userState.isUserAdmin();

  @ViewChild('userForm')
  public userForm: NgForm;

  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  constructor(private stateService: StateService,
    private userState: UserState,
    private userApiService: UserApiService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode,
    private apiUtils: ApiUtilsService
  ) { }

  save() {
    if (!this.userForm.invalid) {
      let companyId = this.userState.getSelectedCompanyId();
      this.loading = true;
      this.userApiService.add(companyId, this.user.username, this.user)
        .then(() => {
          this.tracker.userEvent('User Created', this.userState.getUsername(), false, {
            invitedEmail: this.user.username
          });

          this.stateService.go('apps.user.details', {
            username: this.user.username
          });
        })
        .catch((e) => {
          let error = this.apiUtils.getError(e);
          this.formError = 'User could not be added.';
          this.apiError = this.processErrorCode(e);

          if (error.code === 409) {
            this.apiError = `A User with the Username '${this.user.username}' already belongs
              to another Company. To add them to this Company, they must sign in
              and delete themselves from their current Company by opening their
              User Settings and clicking the Delete button.`;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }


}
