import { Injectable } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private trackerService: TrackerService,
    private userState: UserState,
    private userApiService: UserApiService,
  ) { }

  updateUser(username, profile) {
    return this.userApiService.update(username, profile, this.userState.isUserAdmin())
      .then((resp) => {
        this.userState.updateUserProfile(resp.item);

        this.trackerService.userEvent('User Updated', this.userState.getUsername(), this.userState.checkUsername(
          username), {
          updatedUserId: username,
          updatedUserCompanyRole: profile.companyRole ? profile.companyRole : ''
        });

        console.debug('User Profile updated');

        return resp;
      });
  }
}
