import { Injectable } from '@angular/core';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class YouTubeValidationService {

  private static readonly API_KEY = 'AIzaSyDsKn1Xo09KnO_vVatbPlqJ7cwZgI5wsyk';
  private static readonly VIDEO_URL = `https://youtube.googleapis.com/youtube/v3/videos?part=contentDetails%2Cstatus&key=${YouTubeValidationService.API_KEY}&id=`;
  private static readonly PLAYLIST_URL = `https://youtube.googleapis.com/youtube/v3/playlists?part=contentDetails%2Cstatus&key=${YouTubeValidationService.API_KEY}&id=`;

  constructor(
    private promiseUtils: PromiseUtilsService,
    private httpClient: HttpClient
  ) { }

  validate(url) {
    if (!url) {
      return Promise.resolve('VALID');
    }

    const deferred = this.promiseUtils.generateDeferredPromise();

    const { videoId, playlistId} = this._parseYouTubeUrl(url);

    const apiUrl = videoId ? YouTubeValidationService.VIDEO_URL + videoId : YouTubeValidationService.PLAYLIST_URL + playlistId;

    this.httpClient.get( apiUrl )
    .toPromise()
    .then((response: any) => {
      const data = response.items?.[0];

      if (!data) {
        if (videoId) {
          return deferred.resolve('VIDEO_NOT_FOUND');
        }
        return deferred.resolve('PLAYLIST_NOT_FOUND');
      }

      if (data.kind === 'youtube#video' && data.status && !data.status.embeddable) {
        return deferred.resolve('NOT_EMBEDDABLE');
      }

      return deferred.resolve('VALID');
    }, (response) => {
      if (response.status === 401) {
        return deferred.resolve('NOT_PUBLIC');
      }
      return deferred.resolve('DELETED');
    })
    .catch( (err) => {
      deferred.reject(err);
    });

    return deferred.promise;
  }

  _parseYouTubeUrl(url) {
    let videoId = this._getUrlParam(url, 'v');
    const playlistId = this._getUrlParam(url, 'list');

    // Video ID can be part of the URL path. Examples:
    // "https://youtu.be/11122233344",
    // "https://www.youtube.com/embed/11122233344"
    if (url && !videoId) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(embed\/(?!videoseries)))([^#&?]*).*/;
      const match = url.match(regExp);
      videoId = match && match[5].length === 11 ? match[5] : null;
    }

    return { videoId, playlistId };
  }

  _getUrlParam(url, param) {
    try {
      return new URL(url).searchParams.get(param);
    } catch (error) {
      return null;
    }
  }

}
