import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { YouTubeValidationService } from '../services/youtube-validation-service.service';

@Component({
  selector: 'template-component-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YouTubeComponent {

  spinner = false;
  componentId;
  source;
  volume;
  validationResult;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
      private attributeDataFactory: AttributeDataService,
      private youTubeValidationService: YouTubeValidationService,
      private changeDetectorRef: ChangeDetectorRef,
      private insecureUrl: InsecureUrl) {
    componentsFactory.registerDirective({
      type: 'rise-youtube',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;

        this._load();
      }
    });
  }

  _load() {
    this.source = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'source');
    this.volume = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'volume') || 0;
  }

  _isValidYouTubeUrl(url) {
    /**
     * JavaScript function to match (and return) the video Id
     * of any valid Youtube Url, given as input string.
     * @author: Stephan Schmitz <eyecatchup@gmail.com>
     * @url: https://stackoverflow.com/a/10315969/624466
     */
    var urlWithVideoId = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    var urlWithPlaylistId = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:playlist\?list=|playlist\?.+&list=))(?:\S+)?$/;

    return urlWithVideoId.test(url) || urlWithPlaylistId.test(url);
  }

  _validateSource() {
    //clear the error
    this.validationResult = '';

    var _source = !this.source ? '' : this.source.trim();
    if (_source === '') {
      //empty string is allowed
      return true;
    }

    if (this.insecureUrl(_source)) {
      this.source = _source.replace('http://', 'https://');
    }

    if (this._isValidYouTubeUrl(_source)) {
      return true;
    }

    this.validationResult = 'INVALID';
    return false;
  }

  saveSource() {
    if (this._validateSource()) {
      this.validationResult = 'VALID';

      this.attributeDataFactory.setAttributeData(this.componentId, 'source', this.source);

      this.spinner = true;
      this.changeDetectorRef.detectChanges();

      this.youTubeValidationService.validate(this.source)
      .then( (result) => {
        this.validationResult = result;
      })
      .finally( () => {
        this.spinner = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  saveVolume() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'volume', this.volume);
  }
}
