import { Injectable } from '@angular/core';

import { RiseAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class UserauthApiService {

  constructor(private riseAPILoader: RiseAPILoader) { }

  add(username, password, hash) {
    var obj = {
      data: {
        username: username,
        password: password,
        joinHash: hash ? hash : ''
      }
    };
    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.add(obj);
      })
      .then((resp) => {
        console.debug('added user credentials', resp);

        return resp;
      })
      .catch(function (e) {
        console.error('Failed to add credentials.', e);

        throw e;
      });
  }

  updatePassword(username, oldPassword, newPassword) {
    var obj = {
      data: {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.updatePassword(obj);
      })
      .then((resp) => {
        console.debug('update user credentials resp', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to update credentials.', e);

        throw e;
      });
  }

  login(username, password) {
    var obj = {
      data: {
        username: username,
        password: password
      }
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.login(obj);
      })
      .then((resp) => {
        console.debug('login successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to login user.', e);

        throw e;
      });
  }

  refreshToken(username, token) {
    var obj = {
      data: {
        token: token
      }
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.refreshToken(obj);
      })
      .then((resp) => {
        console.debug('token refresh successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to refresh token.', e);

        throw e;
      });
  }

  requestPasswordReset(username) {
    var obj = {
      data: {
        username: username
      }
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.requestPasswordReset(obj);
      })
      .then((resp) => {
        console.debug('Request password reset successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to request password reset.', e);

        throw e;
      });
  }

  resetPassword(username, passwordResetToken, newPassword) {
    var obj = {
      data: {
        username: username,
        passwordResetToken: passwordResetToken,
        newPassword: newPassword
      }
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.resetPassword(obj);
      })
      .then((resp) => {
        console.debug('Reset password successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to reset password.', e);

        throw e;
      });
  }

  isSsoEnabled(username) {
    var obj = {
      id: username
    };

    return this.riseAPILoader().then((coreApi) => {
        return coreApi.userauth.isSsoEnabled(obj);
      })
      .then((resp) => {
        console.debug('Is SSO Enabled successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to check is SSO Enabled.', e);

        throw e;
      });
  }

}