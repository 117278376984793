import { Component, Input, Output, EventEmitter, OnInit, Injector, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';

import { GadgetService } from '../../services/gadget.service';
import { EditorService } from '../../services/editor.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { SettingsService } from '../../services/settings.service';
import { PlaceholderPlaylistService } from '../../services/placeholder-playlist.service';
import { PresentationParserService } from '../../services/presentation-parser.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss'],
  providers: [
    {
      deps: ['$injector'],
      provide: '$scope',
      useFactory: ($injector: Injector) => $injector.get('$rootScope').$new()
    }
  ]
})
export class PlaylistItemComponent extends ModalComponent implements OnInit {

  @Input() item: any;
  @Output() itemChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('playlistItemForm')
  public playlistItemForm: NgForm;

  _item: any;
  widgetName: string;

  isDeprecatedItem;

  PREVIOUS_EDITOR_URL = environment.RVA_URL + '/#/PRESENTATION_MANAGE' + ((
      this.editorService.presentation.id) ? '/id=' + this.editorService.presentation
    .id : '') + '?cid=' + this.companyStateService.getSelectedCompanyId();

  constructor(public modalRef: BsModalRef,
    private companyStateService: CompanyStateService,
    private placeholderPlaylistService: PlaceholderPlaylistService,
    private editorService: EditorService,
    private settingsService: SettingsService,
    private gadgetService: GadgetService,
    private presentationParserService: PresentationParserService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this._item = _.cloneDeep(this.item);

    if (this._item.type === 'presentation') {
      this.widgetName = 'Embedded Presentation';
    } else if (!this._item.objectReference && this._item.settingsUrl) {
      this.widgetName = this._item.name;
    } else if (this._item.objectReference && this._item.type === 'widget') {
      this.gadgetService.getGadgetById(this._item.objectReference)
        .then((gadget) => {
          this.widgetName = gadget.name;
        });
    }

    this.isDeprecatedItem = this.presentationParserService.isDeprecatedItem(this._item.type);
  }

  showSettingsModal() {
    this.settingsService.showSettingsModal(this._item, true);
  }

  save() {
    _.assign(this.item, this._item);

    this.placeholderPlaylistService.updateItem(this.item);

    this.dismiss();
  }

  dismiss() {
    super.resolve();
  }

}
