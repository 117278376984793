import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { UserState, AnalyticsFactory, BigQueryLogging } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  constructor(
    private userState: UserState,
    private analyticsFactory: AnalyticsFactory,
    private bigQueryLogging: BigQueryLogging,
  ) { }

  // openIdTracker
  OPENID_EVENTS_TO_BQ = [
    'silent renew error'
  ];

  openidEvent(openidEventType, profile, eventProperties?) {
    if (openidEventType) {
      profile = profile || {};
      eventProperties = eventProperties || {};

      var userId = this.userState.getUsername();
      var email = this.userState.getUserEmail() || profile.email;

      angular.extend(eventProperties, {
        openidEventType: openidEventType,
        userId: userId,
        email: email,
        googleUserId: profile.sub,
        companyId: this.userState.getSelectedCompanyId()
      });

      this.analyticsFactory.track('OpenId Event', eventProperties);

      if (this.OPENID_EVENTS_TO_BQ.indexOf(openidEventType) !== -1) {
        this.bigQueryLogging.logEvent('OpenId silent renew error', eventProperties.errorMessage, null, userId ||
          email);
      }
    }
  }

  // purchaseFlowTracker
  productAddedEvent(plan) {
    this.analyticsFactory.track('Product Added', {
      id: plan.productCode,
      name: plan.name,
      price: plan.isMonthly ? plan.monthly.billAmount : plan.yearly.billAmount,
      displaysCount: plan.displays,
      category: 'Plans',
      inApp: false
    });
  }

  placeOrderClickedEvent(properties) {
    this.analyticsFactory.track('Place Order Clicked', properties);
  }

  orderPayNowClickedEvent(properties) {
    this.analyticsFactory.track('Order Pay Now Clicked', properties);
  }

  // batchOperationsTracker
  batchOperationEvent(eventName?, operation?, items?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.operationName = operation.name;
      properties.itemsCount = items.length;
      properties.companyId = this.userState.getSelectedCompanyId();

      this.analyticsFactory.track(eventName, properties);
    }
  }

  // companyTracker
  COMPANY_EVENTS_TO_BQ = [
    'Company Created'
  ];

  companyEvent(eventName?, companyId?, companyName?, isUserCompany?) {
    if (eventName) {
      this.analyticsFactory.track(eventName, {
        companyId: companyId,
        companyName: companyName,
        isUserCompany: isUserCompany
      });
      if (this.COMPANY_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, companyName, null,
          this.userState.getUsername(), companyId);
      }
    }
  }

  // userTracker
  USER_EVENTS_TO_BQ = [];

  userEvent(eventName?, userId?, isSelf?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.userId = userId;
      properties.companyId = this.userState.getSelectedCompanyId();
      properties.isSelf = isSelf;

      this.analyticsFactory.track(eventName, properties);
      if (this.USER_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName);
      }
    }
  }

  // presentationTracker
  PRESENTATION_EVENTS_TO_BQ = [
    'Presentation Created',
    'New Presentation',
    'Template Copied',
    'HTML Template Copied'
  ];

  presentationEvent(eventName?, presentationId?, presentationName?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.presentationId = presentationId;
      properties.presentationName = presentationName;
      properties.companyId = this.userState.getSelectedCompanyId();

      this.analyticsFactory.track(eventName, properties);
      if (this.PRESENTATION_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, presentationId);
      }
    }
  }

  // scheduleTracker
  SCHEDULE_EVENTS_TO_BQ = [
    'Schedule Created'
  ];

  scheduleEvent(eventName?, scheduleId?, scheduleName?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.scheduleId = scheduleId;
      properties.scheduleName = scheduleName;
      properties.companyId = this.userState.getSelectedCompanyId();

      this.analyticsFactory.track(eventName, properties);

      if (this.SCHEDULE_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, scheduleId);
      }
    }
  }

  // displayTracker
  DISPLAY_EVENTS_TO_BQ = [
    'Display Created'
  ];

  displayEvent(eventName?, displayId?, displayName?, eventProperties?) {
    if (eventName) {
      eventProperties = eventProperties || {};
      angular.extend(eventProperties, {
        displayId: displayId,
        displayName: displayName,
        userId: this.userState.getUsername(),
        email: this.userState.getUserEmail(),
        companyId: this.userState.getSelectedCompanyId(),
        companyName: this.userState.getSelectedCompanyName()
      });

      this.analyticsFactory.track(eventName, eventProperties);

      if (this.DISPLAY_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, displayId);
      }
    }
  }

}

angular.module('risevision.common.components')
  .factory('trackerFactory', downgradeInjectable(TrackerService));
