import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as _ from 'lodash';

import { UserState } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

import { CompanyRoleModalComponent } from '../components/company-role-modal/company-role-modal.component';
import { CompanyIcpModalComponent } from '../components/company-icp-modal/company-icp-modal.component';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { UserService } from './user.service';
import { StateService } from '@uirouter/angular';

@Injectable({
  providedIn: 'root'
})
export class CompanyIcpService {

  public static readonly COMPANY_ROLE_FIELDS = [
    ['IT/Network Administrator', 'it_network_administrator'],
    ['Facilities', 'facilities'],
    ['Professor/Instructor/Teacher', 'professor_instructor_teacher'],
    ['Marketing', 'marketing'],
    ['Designer', 'designer'],
    ['Executive/Business Owner', 'executive_business_owner'],
    ['Reseller/Integrator', 'reseller_integrator'],
    ['Architect/Consultant', 'architect_consultant'],
    ['Administrator/Volunteer/Intern', 'administrator_volunteer_intern'],
    ['Developer', 'developer'],
    ['Other', 'other']
  ];
  public static readonly EDUCATION_COMPANY_ROLE_FIELDS = [
    ['IT', 'education_it'],
    ['Librarian', 'education_librarian'],
    ['Marketing/Communications', 'education_marketing_communications'],
    ['Other', 'education_other'],
    ['Principal', 'education_principal'],
    ['Vice Principal', 'education_vice_principal'],
    ['Media Specialist', 'education_media_specialist'],
    ['Learning/Technology Coach', 'education_learning_technology_coach'],
    ['Receptionist/Admin', 'education_receptionist_admin'],
    ['Guidance Counselor', 'education_guidance_counselor'],
    ['Athletic Director/Coach/Physical Education Teacher', 'education_athletic_director_coach_teacher'],
    ['Superintendent', 'education_superintendent'],
    ['Teacher', 'education_teacher'],
    ['Professor', 'education_professor'],
    ['Dean', 'education_dean'],
    ['Student', 'education_student']
  ];
  public static readonly COMPANY_INDUSTRY_FIELDS = [
    ['K-12 Education', 'PRIMARY_SECONDARY_EDUCATION'],
    ['Higher Education', 'HIGHER_EDUCATION', ],
    ['Religious', 'RELIGIOUS_INSTITUTIONS', ],
    ['Nonprofit', 'PHILANTHROPY', ],
    ['Retail', 'RETAIL', ],
    ['Restaurants and Bars', 'RESTAURANTS', ],
    ['Hospital and Healthcare', 'HOSPITAL_HEALTH_CARE', ],
    ['Libraries', 'LIBRARIES', ],
    ['Financial Services', 'FINANCIAL_SERVICES', ],
    ['Gyms and Fitness', 'HEALTH_WELLNESS_AND_FITNESS', ],
    ['Hotels and Hospitality', 'HOSPITALITY'],
    ['Corporate Offices', 'EXECUTIVE_OFFICE'],
    ['Manufacturing', 'INDUSTRIAL_AUTOMATION'],
    ['Government', 'GOVERNMENT_ADMINISTRATION'],
    ['Auto Dealerships', 'AUTOMOTIVE', ],
    ['Marketing and Advertising', 'MARKETING_AND_ADVERTISING', ],
    ['Technology and Integrator', 'INFORMATION_TECHNOLOGY_AND_SERVICES'],
    ['Warehousing', 'WAREHOUSING'],
    ['Logistics and Supply Chain', 'LOGISTICS_AND_SUPPLY_CHAIN'],
    ['Supermarkets', 'SUPERMARKETS'],
    ['Construction', 'CONSTRUCTION'],
    ['Senior Living', 'SENIOR_LIVING'],
    ['Real Estate', 'REAL_ESTATE'],
    ['Other', 'OTHER']
  ];
  public static readonly COMPANY_SIZE_FIELDS = [
    ['Solo', '1'],
    ['Fewer than 20 employees', '2'],
    ['21-50 employees', '21'],
    ['51-250 employees', '51'],
    ['More than 250 employees', '250']
  ];
  private readonly COMPANY_ICP_WRITABLE_FIELDS = [
    'companyIndustry'
  ];
  private readonly COMPANY_ROLE_WRITABLE_FIELDS = [
    'companyRole'
  ];

  constructor(private modalService: ModalService,
    private broadcaster: BroadcasterService,
    private stateService: StateService,
    private userState: UserState,
    private companyApiService: CompanyApiService,
    private userService: UserService) { }

  init() {
    this.broadcaster.on('risevision.company.selectedCompanyChanged', () => {
      //make sure user registration process is complete
      if (this.stateService.current.name.indexOf('common.auth') !== -1) {
        return false;
      }

      if (!this._checkIcpCollection()) {
        this._checkRoleCollection();
      }
    });
  }

  _saveIcpData(result) {
    var company = result.company;
    var companyId = company.id;

    company = _.pick(company, this.COMPANY_ICP_WRITABLE_FIELDS);

    this.companyApiService.updateCompany(companyId, company)
      .then(() => {
        console.debug('Company Profiles updated');
      });
  }

  _checkIcpCollection() {
    var company = this.userState.getCopyOfSelectedCompany(true);

    // Rise user should not be asked to confirm industry of a sub-company
    if (this.userState.isRiseAdmin()) {
      return false;
    }

    // Has industry been collected?
    if (company.companyIndustry) {
      return false;
    }

    this.showIcpModal(company)
      .then((result) => {
        this._saveIcpData(result);
      });

    return true;
  }

  _checkUserCreationDate(user) {
    var creationDate = ((user && user.termsAcceptanceDate) ?
      (new Date(user.termsAcceptanceDate)) : (new Date()));

    var yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    return creationDate < yesterdayDate;
  }

  _saveRoleData(result) {
    var user = result.user;
    var username = user.username;

    user = _.pick(user, this.COMPANY_ROLE_WRITABLE_FIELDS);

    this.userService.updateUser(username, user);
  }

  _checkRoleCollection() {
    var user = this.userState.getCopyOfProfile(true);

    if (!this.userState.isEducationCustomer(true) || !this._checkUserCreationDate(user)) {
      return;
    }

    // Has company role been collected?
    if (user.companyRole) {
      return;
    }

    this.showRoleModal(user)
      .then((result) => {
        this._saveRoleData(result);
      });

  }

  showRoleModal(user) {
    return this.modalService.showLegacyModal(CompanyRoleModalComponent, {
      size: 'md',
      backdrop: 'static', //prevent from closing modal by clicking outside
      keyboard: false, //prevent from closing modal by pressing escape
      initialState: {
        user
      }
    });
  }

  showIcpModal(company) {
    return this.modalService.showLegacyModal(CompanyIcpModalComponent, {
      size: 'md',
      backdrop: 'static', //prevent from closing modal by clicking outside
      keyboard: false, //prevent from closing modal by pressing escape
      initialState: {
        company
      }
    });
  }

}

angular.module('risevision.apps.services')
  .value('COMPANY_INDUSTRY_FIELDS', CompanyIcpService.COMPANY_INDUSTRY_FIELDS)
  .factory('companyIcpFactory', downgradeInjectable(CompanyIcpService));
