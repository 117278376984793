import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { CustomAuthService } from './services/custom-auth.service';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { RegistrationComponent } from './components/registration/registration.component';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { routes } from './auth.routes';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { LoginComponent } from './components/login/login.component';
import { AuthFormComponent } from './components/auth-form/auth-form.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';

@NgModule({
  declarations: [
    RequestPasswordResetComponent,
    ResetPasswordConfirmComponent,
    RegistrationComponent,
    UnsubscribeComponent,
    LoginComponent,
    AuthFormComponent,
    CreateAccountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ComponentsModule,
    SharedModule,
    CommonHeaderModule
  ]
})
export class AuthModule {
    //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [ ];
  static providers = [ CustomAuthService ];
}
