import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

import * as _ from 'lodash';

import { HelpWidgetFactory, UserState } from 'src/app/ajs-upgraded-providers';
import { CompanyIcpService } from '../../services/company-icp.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { ModalStackService } from 'src/app/components/modals/modal-stack.service';

@Component({
  selector: 'user-fields',
  templateUrl: './user-fields.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ['./user-fields.component.scss']
})
export class UserFieldsComponent implements OnInit {
  USER_ROLES = [
    { key: 'ap', value: 'Teacher', checked: false, beta: true },
    { key: 'ce', value: 'Content Editor', checked: false },
    { key: 'cp', value: 'Content Publisher', checked: false },
    { key: 'da', value: 'Display Administrator', checked: false },
    { key: 'ua', value: 'System Administrator', checked: false },
    { key: 'pu', value: 'Store Purchaser', checked: false },
    { key: 'sa', value: 'Rise System Administrator', checked: false },
    { key: 'ba', value: 'Rise Store Administrator', checked: false }
  ];

  private _user: any;
  @Input() set user(value: any) {
    this._user = value;
    this.updateRoles();
  }

  get user(): any {
    return this._user;
  }

  public userForm: NgForm;

  COMPANY_ROLE_FIELDS;

  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  constructor(
    private controlContainer: ControlContainer,
    private userState: UserState,
    private trackerService: TrackerService,
    private featuresService: FeaturesService,
    public helpWidgetService: HelpWidgetFactory
  ) { }

  ngOnInit() {
    this.userForm = <NgForm>this.controlContainer;

    if (this.userState.isEducationCustomer(this.userState.checkUsername(this.user.username))) {
      this.COMPANY_ROLE_FIELDS = CompanyIcpService.EDUCATION_COMPANY_ROLE_FIELDS;
    } else {
      this.COMPANY_ROLE_FIELDS = CompanyIcpService.COMPANY_ROLE_FIELDS;
    }
  }

  get teacherRoleAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_TEACHER_ROLE);
  }

  updateRoles() {
    for (let role of this.USER_ROLES) {
      role.checked = this.isRoleChecked(role);
    }
  }

  isRoleChecked(role) {
    return this.user.roles && this.user.roles.indexOf(role.key) > -1;
  }

  onCheckboxChange(role) {
    if (!this.user.roles) {
      this.user.roles = [];
    }

    if (role.checked) {
      if (role.key === 'ap') {
        this.user.roles = ['ap'];
      } else {
        const apIndex = this.user.roles.indexOf('ap');
        if (apIndex >= 0) {
          this.user.roles.splice(apIndex, 1);
        }
        this.user.roles.push(role.key);
      }
    } else {
      const i = this.user.roles.indexOf(role.key);
      if (i >= 0) {
        this.user.roles.splice(i, 1);
      }
    }

    this.trackerService.userEvent('User Roles Changed', this.userState.getUsername(), this.userState.checkUsername(this.user.username), {
      updatedUserId: this.user.username,
      roles: this.user.roles.join(', ')
    });

    this.updateRoles();
  }

  editRoleAllowed(role) {
    if (role.key === 'ap') {
      return !this.userState.checkUsername(this.user.username) && this.teacherRoleAvailable;
    }
    if (this.userState.isRiseAdmin()) {
      return true;
    } else if (this.userState.isUserAdmin()) {
      if (role.key === 'sa' || role.key === 'ba') {
        return false;
      } else if (role.key === 'ua' &&
        this.userState.checkUsername(this.user.username)) {
        //cannot unassign oneself from ua
        return false;
      } else {
        return true;
      }
    } else {
      //do not allow user to check/uncheck role by default
      return false;
    }
  }

  editRoleVisible(role) {
    if (this.userState.isSelectedCompanyChargebee() && role.key === 'pu') {
      return false;
    } else if (role.key === 'ap') {
      return this.userState.isK12Customer() || this.userState.isHigherEdCustomer();
    } else if (this.userState.isRiseAdmin()) {
      if (this.userState.isSubcompanySelected() && (role.key === 'sa' || role.key ===
          'ba')) {
        return false;
      } else {
        return true;
      }
    } else if (this.userState.isUserAdmin() || this.userState.isRiseVisionUser()) {
      if (role.key === 'sa' || role.key === 'ba') {
        return false;
      } else {
        return true;
      }
    } else {
      // in practice should never reach here
      return false;
    }
  }

  closeAllModals() {
    ModalStackService.closeAll();
  }

  showUpgradePlanModal() {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_TEACHER_ROLE).then((result) => {
      if (result) {
        this.closeAllModals();
      }
    });
  }
}
