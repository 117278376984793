import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

import { UserState, UrlStateService } from 'src/app/ajs-upgraded-providers';

import { RegistrationService } from '../../services/registration.service';
import { CompanyIcpService } from 'src/app/common-header/services/company-icp.service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {


  COMPANY_INDUSTRY_FIELDS = this.sort(CompanyIcpService.COMPANY_INDUSTRY_FIELDS);
  isSignUp = this.userState.isSignUp();
  unconfirmedGoogleAccount = !this.userState.isRiseAuthUser() && this.registrationService.newUser && !this.userState._state.joinaccountState;
  unexpectedGoogleAccount = !this.userState.isRiseAuthUser() && this.userState._state.joinaccountState && this.userState._state.joinaccountState.email !== this.userState._state.user.username;
  username = this.userState.getUsername();
  joinaccountState = this.userState._state.joinaccountState || {};

  @ViewChild('registrationForm')
  public registrationForm: NgForm;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private userState: UserState,
    protected registrationService: RegistrationService,
    private urlStateService: UrlStateService) {}

  ngOnInit() {
    this.populateIndustryFromUrl();
  }

  confirmGoogleAccount() {
    this.unconfirmedGoogleAccount = false;
  }

  newUserK12Selected() {
    return this.registrationService.newUser && this.registrationService.company.companyIndustry === 'PRIMARY_SECONDARY_EDUCATION';
  }

  save() {
    this.registrationForm.controls.accepted.markAsDirty();
    this.registrationForm.controls.firstName.markAsDirty();
    this.registrationForm.controls.lastName.markAsDirty();
    this.registrationForm.controls.companyName.markAsDirty();
    this.registrationForm.controls.companyIndustry.markAsDirty();

    if (!this.registrationForm.invalid) {
      this.registrationService.register();
    }
  }

  populateIndustryFromUrl() {

    var industryName = this.urlStateService.getUrlParam('industry');

    if (this.registrationService.newUser && industryName) {

      this.COMPANY_INDUSTRY_FIELDS.forEach((industry) => {
        if (industryName === industry[0]) {
          this.registrationService.company.companyIndustry = industry[1];
        }
      });
    }
  }

  sort(items) {
    return _.sortBy(items, item => {
      return item[0];
    });
  }

  onChange() {
    this.changeDetectorRef.detectChanges();
  }

}