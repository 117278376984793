import { Injectable } from '@angular/core';

import Compressor from 'compressorjs';
import { BigQueryLogging } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class CompressorJsService {
  get(domFileItem, options): any {
    return new Compressor(domFileItem, options);
  }
}

@Injectable({
  providedIn: 'root'
})
export class JpegCompressorService {
  disabled = false;

  constructor(
    private bigQueryLogging: BigQueryLogging,
    private compressorJsService: CompressorJsService
  ) { }

  compress (fileItem, folderPath) {
    return new Promise((resolve) => {

      if (this.disabled || fileItem.file.type !== 'image/jpeg') {
        return resolve(true);
      }

      this.compressorJsService.get(fileItem.domFileItem, {
        quality: 0.7,
        checkOrientation: false,
        success: (result) => {
          this.bigQueryLogging.logEvent('image compressed', folderPath + fileItem.file.name, result.size / fileItem.file.size);

          fileItem.domFileItem = result;
          fileItem.file.size = result.size;
          resolve(true);
        },
        error: (err) => {
          console.debug(err);
          this.bigQueryLogging.logEvent('image compress error', folderPath + fileItem.file.name + ' | ' + err.message);
          this.disabled = true;
          resolve(true);
        }
      });
    })
  }

}
