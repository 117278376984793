import { Directive, Component, HostBinding, HostListener } from '@angular/core';

import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

import { UserState, UserAuthFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';

@Directive({
  selector: '[sign-out-button]'
})
export class SignOutButtonDirective {

  constructor(private userState: UserState,
    private userAuthFactory: UserAuthFactory,
    private modalService: ModalService) { }

  _signOut() {
    this.userAuthFactory.signOut()
      .then(() => {
        console.debug('User signed out.');
      })
      .catch((err) => {
        console.debug('User sign out failed', err);
      });
  }

  logout() {
    if (this.userState.isRiseAuthUser()) {
      this._signOut();
    } else {
      return this.modalService.confirm('Sign Out of Rise Vision',
        'Signing out does not sign you out of your Google Account. If you are on a shared computer you should sign out of your Google Account.',
        'Sign Out',
        'Cancel')
        .then(() => {
          this._signOut();
        })
        .catch(() => {});
    }
  }

  @HostListener('click') onClick() {
    this.logout();
  }

}

/* eslint-disable @angular-eslint/component-class-suffix */
@Component({
  selector: 'signOutButton',
  template: `<ng-content></ng-content>`,
  providers: [SignOutButtonDirective]
})
export class SignOutButtonDirectiveWrapper {
  @HostBinding('attr.signOutButton') directive;
  constructor(directive: SignOutButtonDirective){
    this.directive = directive;
  }

  @HostListener('click') onClick() {
    this.directive.logout();
  }
}

const allowAttribute = directiveFactory => [ '$injector', $injector =>
    Object.assign($injector.invoke(directiveFactory), {restrict: 'EA'})
];
angular.module('risevision.common.header.directives')
  .directive('signOutButton', allowAttribute(downgradeComponent({
    component: SignOutButtonDirectiveWrapper,
  })));
