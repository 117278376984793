<div rvSpinner="auth-buttons-login" [rvShowSpinner]="loading">
  <div>
    <div class="panel-body">
      <h4>Sign Up</h4>
      @if (!joinAccount) {
        <div>
          <p>Start your FREE 14-day trial.</p>
        </div>
      }
      @else {
        <div>
          @if (companyName) {
            <p>Join {{companyName}}</p>
          }
          @else {
            <p>Complete your account registration</p>
          }
        </div>
      }
      <!-- CUSTOM LOGIN -->
      <div>
        <!-- Error Message -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.message">
          <p><strong>{{errors.messageTitle}}</strong></p>
          <p class="u_remove-bottom" [innerHTML]="errors.message"></p>
        </div>
        <!-- Generic Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.genericError">
          <p class="u_remove-bottom">
            <i class="fa fa-warning icon-left"></i>
            <span id="signup-failure-error">Registration failed. <br/>If you are having problems registering, please check this <a href="https://help.risevision.com/hc/en-us/articles/115001402743-I-am-having-trouble-logging-in-what-can-I-do-" target="_blank">Help Center article</a>.</span>
          </p>
        </div>
        <!-- Duplicate Account Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.duplicateError || messages.resetPassword">
          <p class="u_remove-bottom">
            <i class="fa fa-warning icon-left"></i>
            <span id="already-registered-warning">This email address is already registered. You can <a uiSref="common.auth.unauthorized">sign in</a> with this address.</span>
          </p>
        </div>
        <!-- SSO Login Required Error -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.ssoRequired">
          <p class="u_remove-bottom">
            <i class="fa fa-warning icon-left"></i>
            <span id="sso-required-warning">Your account has been activated, but SSO is required for this company. Please <a uiSref="common.auth.unauthorized">sign in with SSO</a>.</span>
          </p>
        </div>
        <!-- ERROR PANEL 6 -->
        <div class="panel-body bg-danger u_margin-sm-top" [shown]="invalidRequest && messages.inviteResent === undefined && !errors.duplicateError">
          <p class="u_remove-bottom">
            <i class="fa fa-warning icon-left"></i>
            @if (joinAccount) {
              <span id="invalid-request-url">
                The activation link is not valid. Use the button below to send a new activation email.
              </span>
            }
            @else {
              <span id="invited-account-signup">
                This email address has been invited to join a company. Please activate your account using the link in the invitation email.
              </span>
            }
          </p>
        </div>
        <!-- MESSAGE PANEL 3 -->
        @if (messages.inviteResent !== undefined) {
          <div class="panel-body bg-info u_margin-sm-top">
            <p class="u_remove-bottom">
              <i class="fa fa-warning icon-left"></i>
              <span [shown]="messages.inviteResent">The invitation email has been resent. Please use the link in the email to activate your account.</span>
              <span [shown]="!messages.inviteResent">The invitation email could not be sent. If the problem persists, please <a target="_blank" href="mailto:support@risevision.com">contact support</a>.</span>
            </p>
          </div>
        }
        <auth-form
          [credentials]="credentials"
          [isSignUp]="true"
          [joinAccount]="joinAccount"
          [shown]="(!invalidRequest || joinAccount && messages.inviteResent === undefined && !messages.resetPassword) && !errors.ssoRequired"
          [ssoChecked]="true"
          [ssoEnabled]="ssoEnabled"
          [(usePassword)]="usePassword"
          (googleLogin)="googleLogin()"
          (submitForm)="createAccount($event)"
          (loading)="loading = $event"
          [invalidRequest]="invalidRequest && joinAccount"
          (handleInvalidRequest)="resendInviteEmail()">
        </auth-form>
      </div>
    </div>
  </div><!--panel-->
  <div class="align-center u_margin-md-top">
    <p>Already have an account? <a class="madero-link" id="sign-in-link" uiSref="common.auth.unauthorized">Sign in</a>.</p>
  </div>
</div>